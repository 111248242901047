import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "margin-right-5" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  class: "individual-response-table"
}
const _hoisted_7 = { class: "table-header" }
const _hoisted_8 = {
  key: 0,
  class: "table-head",
  scope: "col"
}
const _hoisted_9 = {
  scope: "col",
  class: "table-head user-response-value"
}
const _hoisted_10 = {
  scope: "col",
  class: "table-head"
}
const _hoisted_11 = {
  key: 0,
  class: "user-table-data"
}
const _hoisted_12 = ["title"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "date-time" }
const _hoisted_15 = { class: "delete-button" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["src"]
const _hoisted_18 = {
  key: 0,
  class: "empty-table-row"
}
const _hoisted_19 = {
  class: "empty-table-text",
  colspan: "4"
}
const _hoisted_20 = { key: 1 }
const _hoisted_21 = {
  colspan: "4",
  class: "paginate-td"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_Paginate = _resolveComponent("Paginate")!

  return (_ctx.localNotificationItem != null && _ctx.localNotificationItem.Id > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.modalPopupVisible)
          ? (_openBlock(), _createBlock(_component_ModalPopup, {
              key: 0,
              "show-modal-popup": _ctx.modalPopupVisible,
              "header-content": _ctx.$t('Dialogue.DeleteResponse'),
              "body-heading": _ctx.modalPopupHeading,
              "body-content": _ctx.modalPopupContent,
              "show-close-button": _ctx.showCloseButton,
              "show-cancel-button": _ctx.modalPopupShowCancelButton,
              onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
            }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button", "show-cancel-button"]))
          : _createCommentVNode("", true),
        (!_ctx.isSelectedItemTypeTextListing)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("label", {
                class: "inline-flex show-hide-table-label cursor-pointer",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.ShowIndividualResponseTable && _ctx.ShowIndividualResponseTable(...args)))
              }, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("Dialogue.HighCharts.ShowIndividualResponse")), 1),
                (_ctx.isArrowUp)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.arrowUpImage,
                      alt: "show"
                    }, null, 8, _hoisted_4))
                  : (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      src: _ctx.arrowDownImage,
                      alt: "hide"
                    }, null, 8, _hoisted_5))
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["border-radius-10 description", {'div-individual-responses': _ctx.showIndividualResponses || _ctx.isSelectedItemTypeTextListing}])
        }, [
          (_ctx.showIndividualResponses || _ctx.isSelectedItemTypeTextListing)
            ? (_openBlock(), _createElementBlock("table", _hoisted_6, [
                _createElementVNode("thead", _hoisted_7, [
                  _createElementVNode("tr", null, [
                    (!_ctx.isUsersAnonymous)
                      ? (_openBlock(), _createElementBlock("th", _hoisted_8, _toDisplayString(_ctx.$t("Dialogue.UserFeedbackTable.User")), 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t("Dialogue.UserFeedbackTable.Value")), 1),
                    _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.$t("Dialogue.UserFeedbackTable.DateTime")), 1),
                    _cache[3] || (_cache[3] = _createElementVNode("th", {
                      scope: "col",
                      class: "table-head"
                    }, null, -1))
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userResponses, (userResponse, index) => {
                    return (_openBlock(), _createElementBlock("tr", { key: index }, [
                      (!_ctx.isUsersAnonymous)
                        ? (_openBlock(), _createElementBlock("td", _hoisted_11, [
                            _createElementVNode("span", {
                              title: userResponse.User,
                              class: "user-name-span"
                            }, _toDisplayString(userResponse.User), 9, _hoisted_12)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("td", {
                        class: "user-response-value",
                        innerHTML: userResponse.Value
                      }, null, 8, _hoisted_13),
                      _createElementVNode("td", _hoisted_14, _toDisplayString(_ctx.formatTime(userResponse.CreateTimeUtc)), 1),
                      _createElementVNode("td", _hoisted_15, [
                        _createElementVNode("div", {
                          class: "div-delete-icon cursor-pointer",
                          onClick: ($event: any) => (_ctx.ClearSingleResponseClicked(userResponse.Id))
                        }, [
                          _createElementVNode("img", {
                            src: _ctx.trashImage,
                            alt: "trash icon"
                          }, null, 8, _hoisted_17)
                        ], 8, _hoisted_16)
                      ])
                    ]))
                  }), 128)),
                  (_ctx.totalUsersFeedbacksCount === 0)
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_18, [
                        _createElementVNode("td", _hoisted_19, _toDisplayString(_ctx.$t("NoDataAvailableYet")), 1)
                      ]))
                    : (_openBlock(), _createElementBlock("tr", _hoisted_20, [
                        _createElementVNode("td", _hoisted_21, [
                          _createVNode(_component_Paginate, {
                            modelValue: _ctx.page,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.page) = $event)),
                            "page-count": _ctx.pageCount,
                            "page-range": _ctx.paginationPageRange,
                            "margin-pages": _ctx.paginationPageMargin,
                            "click-handler": _ctx.clickCallback,
                            "prev-text": _ctx.pagiantionPreviewText,
                            "next-text": _ctx.pagiantionNextText,
                            "container-class": 'pagination',
                            "page-class": 'page-item',
                            "page-link-class": 'page-link-item',
                            "prev-link-class": 'preview-link',
                            "next-link-class": 'next-link'
                          }, null, 8, ["modelValue", "page-count", "page-range", "margin-pages", "click-handler", "prev-text", "next-text"])
                        ])
                      ]))
                ])
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ]))
    : _createCommentVNode("", true)
}