import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ajax-loader"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "scroll-top grid-listing"
}
const _hoisted_4 = { class: "flex-container" }
const _hoisted_5 = { class: "right-container-nav" }
const _hoisted_6 = { class: "width25" }
const _hoisted_7 = { class: "width50" }
const _hoisted_8 = { class: "main-heading primary-heading" }
const _hoisted_9 = { class: "add-btn-position" }
const _hoisted_10 = { class: "padding-top-20" }
const _hoisted_11 = { class: "top-margin-in-fields" }
const _hoisted_12 = { class: "filter-sub-heading" }
const _hoisted_13 = { for: "Filter" }
const _hoisted_14 = { class: "color-red" }
const _hoisted_15 = { class: "top-margin-in-fields" }
const _hoisted_16 = { for: "Filter" }
const _hoisted_17 = { value: 0 }
const _hoisted_18 = ["value"]
const _hoisted_19 = { class: "top-margin-in-fields" }
const _hoisted_20 = { for: "Filter" }
const _hoisted_21 = { class: "segment-filter-parent" }
const _hoisted_22 = { class: "segment-filter" }
const _hoisted_23 = { value: 0 }
const _hoisted_24 = ["value"]
const _hoisted_25 = { class: "segment-filter" }
const _hoisted_26 = { value: 0 }
const _hoisted_27 = ["value"]
const _hoisted_28 = { class: "top-margin-in-fields" }
const _hoisted_29 = { for: "Filter" }
const _hoisted_30 = { value: 0 }
const _hoisted_31 = ["value"]
const _hoisted_32 = { class: "top-margin-in-fields" }
const _hoisted_33 = { for: "Filter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.localNotificationLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("img", {
            class: "img-responsive ajax-loader-img",
            src: _ctx.loadingImage,
            alt: "Wait"
          }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.localNotificationLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("button", {
                  class: "btn btn-secondary btn-size-default",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.CloseCreateFilter()))
                }, _toDisplayString(_ctx.$t("Button.Close")), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("Dialogue.Filter.Heading")), 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("button", {
                  class: "btn btn-primary btn-size-default",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.SaveFilter(_ctx.v$)))
                }, _toDisplayString(_ctx.$t("Dialogue.Save")), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              (_ctx.visibleValidationSummary)
                ? (_openBlock(), _createBlock(_component_ValidationSummary, {
                    key: 0,
                    "error-fields": _ctx.validationErrorFields,
                    onCloseClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.CloseValidationSummary($event)))
                  }, null, 8, ["error-fields"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("Dialogue.Filter.SubHeading")), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_13, [
                _createTextVNode(_toDisplayString(_ctx.$t("Dialogue.Filter.FilterName")), 1),
                _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("Asteric")), 1)
              ]),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$.filterTitle.$model) = $event)),
                type: "text",
                class: "form-control",
                maxlength: "100"
              }, null, 512), [
                [
                  _vModelText,
                  _ctx.v$.filterTitle.$model,
                  void 0,
                  { trim: true }
                ]
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("Dialogue.Filter.ParticipantInterest")), 1),
              _createElementVNode("select", {
                id: "selInterest",
                class: "form-control selectBox",
                onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.ChangeParticipantInterest($event)))
              }, [
                _createElementVNode("option", _hoisted_17, _toDisplayString(_ctx.$t("Dialogue.Filter.ChooseInterest")), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableInterests, (interest, index) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: index,
                    value: interest.Id
                  }, _toDisplayString(interest.Name), 9, _hoisted_18))
                }), 128))
              ], 32)
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t("Dialogue.Filter.SegmentCriteria")), 1),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("select", {
                    id: "selInterest",
                    class: "form-control selectBox",
                    onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.filterSegmentChoices($event)))
                  }, [
                    _createElementVNode("option", _hoisted_23, _toDisplayString(_ctx.$t("Dialogue.Filter.ChooseSegment")), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableSegments, (segments, index) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: index,
                        value: segments.Id
                      }, _toDisplayString(segments.Name), 9, _hoisted_24))
                    }), 128))
                  ], 32)
                ]),
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "mid-segment-filter" }, " = ", -1)),
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("select", {
                    id: "selInterest",
                    class: "form-control selectBox",
                    onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.ChangeSegmentChoices($event)))
                  }, [
                    _createElementVNode("option", _hoisted_26, _toDisplayString(_ctx.$t("Dialogue.Filter.SegmentChoice")), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredSegmentsChoices, (segmentchoice, index) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: index,
                        value: segmentchoice.ChoiceId
                      }, _toDisplayString(segmentchoice.ChoiceText), 9, _hoisted_27))
                    }), 128))
                  ], 32)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t("Dialogue.Filter.ParticipantGroup")), 1),
              _createElementVNode("select", {
                id: "selParticipantGroup",
                class: "form-control selectBox",
                onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.ChangeParticipantGroup($event)))
              }, [
                _createElementVNode("option", _hoisted_30, _toDisplayString(_ctx.$t("Dialogue.Filter.ChooseGroup")), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableParticipantGroups, (group, index) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: index,
                    value: group.Id
                  }, _toDisplayString(group.Name), 9, _hoisted_31))
                }), 128))
              ], 32)
            ]),
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t("Dialogue.Filter.YourFilter", {participantCount : _ctx.participantCount})), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}