import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "scroll-top grid-listing" }
const _hoisted_2 = { class: "flex-container" }
const _hoisted_3 = { class: "right-container-nav" }
const _hoisted_4 = { class: "main-title-width" }
const _hoisted_5 = { class: "main-heading primary-heading" }
const _hoisted_6 = { class: "create-btn-position" }
const _hoisted_7 = { class: "padding-top-20" }
const _hoisted_8 = { class: "margin-top-30" }
const _hoisted_9 = {
  key: 0,
  class: "empty-msg-position"
}
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "margin-top-10" }
const _hoisted_12 = { class: "item-position-from-image valign-parent50 width70" }
const _hoisted_13 = { class: "valign description" }
const _hoisted_14 = { class: "valign-parent55 width30 text-right" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "margin-top-15" }
const _hoisted_19 = { class: "folder-create" }
const _hoisted_20 = { class: "item-position-from-image valign-parent50 folder-input width50" }
const _hoisted_21 = {
  key: 0,
  class: "error-vuelidate"
}
const _hoisted_22 = { class: "valign-parent55 text-right width50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createElementVNode("button", {
              class: "btn btn-secondary btn-size-default",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.CloseFolder()))
            }, _toDisplayString(_ctx.$t("Button.Close")), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("Dialogue.ChangeFolder")), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              class: "btn btn-primary btn-size-default",
              style: {"margin-right":"10px"},
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ShowCreateFolder()))
            }, _toDisplayString(_ctx.$t("Button.Create")), 1),
            _createElementVNode("button", {
              class: "btn btn-primary btn-size-default",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.SaveFolder()))
            }, _toDisplayString(_ctx.$t("Button.Save")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.visibleValidationSummary)
            ? (_openBlock(), _createBlock(_component_ValidationSummary, {
                key: 0,
                "error-fields": _ctx.validationErrorFields,
                onCloseClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.CloseValidationSummary($event)))
              }, null, 8, ["error-fields"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("CustomCategoryFolder.SelectFolder")), 1),
        ((_ctx.localFolderItems==null || _ctx.localFolderItems.length==0))
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("p", null, [
                _createElementVNode("i", {
                  class: "font-size-14",
                  innerHTML: _ctx.$t('Dialogue.EmptyScreenMessage')
                }, null, 8, _hoisted_10)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localFolderItemObjects, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.Id,
              class: "margin-top-15",
              style: {"display":"inline-flex","width":"100%"}
            }, [
              _cache[7] || (_cache[7] = _createElementVNode("div", null, [
                _createElementVNode("i", { class: "fa fa-folder-open folder-style" })
              ], -1)),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("span", null, _toDisplayString(item.Name), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                (item.IsSelected)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn btn-primary btn-size-default valign",
                      onClick: ($event: any) => (_ctx.unselectItem(item))
                    }, _toDisplayString(_ctx.$t("Button.Selected")), 9, _hoisted_15))
                  : _createCommentVNode("", true),
                (!item.IsSelected)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "btn btn-secondary btn-size-default valign",
                      onClick: ($event: any) => (_ctx.selectItem(item))
                    }, _toDisplayString(_ctx.$t("Button.Select")), 9, _hoisted_16))
                  : _createCommentVNode("", true)
              ])
            ]))
          }), 128)),
          (_ctx.showCreateFolder)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _cache[8] || (_cache[8] = _createElementVNode("i", { class: "fa fa-folder-open folder-icon" }, null, -1)),
                    _createElementVNode("div", _hoisted_20, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.v$.localFolderItem.Name.$model) = $event)),
                        type: "text",
                        class: _normalizeClass(["form-control valign width100", _ctx.status(_ctx.v$.localFolderItem.Name)]),
                        placeholder: "enter your text here",
                        maxlength: "70"
                      }, null, 2), [
                        [
                          _vModelText,
                          _ctx.v$.localFolderItem.Name.$model,
                          void 0,
                          { trim: true }
                        ]
                      ]),
                      (_ctx.v$.localFolderItem.Name.$error)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString(_ctx.$t("Validation.NameRequired")), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("button", {
                        class: "btn btn-secondary btn-size-default margin-left-15",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.HideCreateFolder()))
                      }, _toDisplayString(_ctx.$t("Button.Cancel")), 1),
                      _createElementVNode("button", {
                        class: "btn btn-primary btn-size-default margin-left-15",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.CreateNewFolder(_ctx.v$)))
                      }, _toDisplayString(_ctx.$t("Button.Save")), 1)
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}