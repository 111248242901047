import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "div-empty-Info-text"
}
const _hoisted_4 = { class: "empty-Info-text-span" }
const _hoisted_5 = {
  key: 0,
  class: "div-individual-responses"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationResponseCounterAndReminder = _resolveComponent("NotificationResponseCounterAndReminder")!
  const _component_NotificationSurveyHighCharts = _resolveComponent("NotificationSurveyHighCharts")!
  const _component_NotificationIndividualResponses = _resolveComponent("NotificationIndividualResponses")!

  return (_ctx.localNotificationItem !== null && _ctx.localNotificationItem !== undefined)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_NotificationResponseCounterAndReminder, { "selected-notification-item": _ctx.localNotificationItem }, null, 8, ["selected-notification-item"])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localNotificationItem.QuestionnaireNotifications, (subDialogueItem, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: subDialogueItem.Id
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["border-radius-10", {'div-chart-title': _ctx.IsChartTypeTextListing(subDialogueItem.SelectedChartTypeId)}])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["border-radius-10", {'div-chart-title': !_ctx.IsChartTypeTextListing(subDialogueItem.SelectedChartTypeId)}])
              }, [
                _createElementVNode("div", null, _toDisplayString(index + 1 +'. '+ subDialogueItem.Title), 1),
                (!_ctx.IsDialogueItemInformation(subDialogueItem.NotificationTypeId) && !_ctx.IsChartTypeTextListing(subDialogueItem.SelectedChartTypeId))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_NotificationSurveyHighCharts, { "selected-notification-item": subDialogueItem }, null, 8, ["selected-notification-item"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.IsDialogueItemInformation(subDialogueItem.NotificationTypeId))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("Notification.DialogueResults.EmptyInformation")), 1)
                    ]))
                  : _createCommentVNode("", true)
              ], 2),
              (!_ctx.IsDialogueItemInformation(subDialogueItem.NotificationTypeId))
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_NotificationIndividualResponses, { "selected-notification-item": subDialogueItem }, null, 8, ["selected-notification-item"])
                  ]))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["div-hr", {'display-none': _ctx.totalQuestionnarieItemCount === (index + 1)}])
            }, _cache[0] || (_cache[0] = [
              _createElementVNode("hr", { class: "horizontal-rule" }, null, -1)
            ]), 2)
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}