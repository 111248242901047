import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "batch-operation" }
const _hoisted_2 = {
  key: 1,
  class: "right-container-margin"
}
const _hoisted_3 = {
  key: 2,
  class: "right-container-margin"
}
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { class: "main-heading-parent" }
const _hoisted_6 = { class: "bulk-action-text-margin" }
const _hoisted_7 = { class: "batch-operation-main-content" }
const _hoisted_8 = { class: "action-middle-div" }
const _hoisted_9 = ["index"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "img-select iconSize" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "selectInformationStyle valign-parent60 item-position-from-image" }
const _hoisted_14 = { class: "valign batch-content-width" }
const _hoisted_15 = { class: "hyperLink questionLinkFont" }
const _hoisted_16 = {
  key: 1,
  class: "action-middle-width"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_NotificationBatchExport = _resolveComponent("NotificationBatchExport")!
  const _component_NotificationBatchChangeFolder = _resolveComponent("NotificationBatchChangeFolder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.modalHeader,
          "body-heading": _ctx.modalPopupHeading,
          "body-content": _ctx.modalPopupContent,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button"]))
      : _createCommentVNode("", true),
    (_ctx.showExportSelected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_NotificationBatchExport, {
            "selected-bulk-operation-model": _ctx.localSelectedBulkOperationModel,
            "selected-items": _ctx.selectedItems,
            "participant-segments": _ctx.participantSegments,
            onSelectBack: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ShowExportResponsesBatchPane($event)))
          }, null, 8, ["selected-bulk-operation-model", "selected-items", "participant-segments"])
        ]))
      : (_ctx.showChangeFolder)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_NotificationBatchChangeFolder, {
              "selected-bulk-operation-model": _ctx.localSelectedBulkOperationModel,
              "selected-items": _ctx.selectedItems,
              "folder-items": _ctx.localfolderitems,
              onSelectBack: _cache[2] || (_cache[2] = ($event: any) => (_ctx.ShowChangeFoldersBatchPane($event)))
            }, null, 8, ["selected-bulk-operation-model", "selected-items", "folder-items"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h4", null, _toDisplayString(_ctx.$t("Program.Batch.Header1")) + " " + _toDisplayString(_ctx.selectedItems.length) + " " + _toDisplayString(_ctx.$t("Program.Batch.Header2")), 1)
            ]),
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("Bulk.SelectAction")), 1),
            _createElementVNode("div", _hoisted_7, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "action-side-width" }, null, -1)),
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bulkEditOperations.arrBulkOperations, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.Id,
                    index: index,
                    class: _normalizeClass(["inner-content", _ctx.evenIndex(index)==true?'action-content-width-left':'action-content-width-right'])
                  }, [
                    (_ctx.ShowBatchOperationOption(item))
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "inner-content",
                          onClick: ($event: any) => (_ctx.handle_function_call(item.objModel,item.ConfirmationMessage,item.ShowConfirmationPopup))
                        }, [
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("img", {
                              src: item.IconUrl,
                              style: _normalizeStyle(item.IconPadding)
                            }, null, 12, _hoisted_12)
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("label", _hoisted_15, _toDisplayString(item.Name), 1),
                              _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                              _createElementVNode("span", null, _toDisplayString(item.Description), 1)
                            ])
                          ])
                        ], 8, _hoisted_10))
                      : _createCommentVNode("", true),
                    (_ctx.evenIndex(index))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_16))
                      : _createCommentVNode("", true)
                  ], 10, _hoisted_9))
                }), 128))
              ]),
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "action-side-width" }, null, -1))
            ])
          ]))
  ]))
}