import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "notificationListViewPane",
  class: "scroll-top grid-listing"
}
const _hoisted_2 = { class: "flex-container" }
const _hoisted_3 = { class: "right-container-margin" }
const _hoisted_4 = { class: "right-container-nav" }
const _hoisted_5 = { class: "width50" }
const _hoisted_6 = { class: "main-heading primary-heading" }
const _hoisted_7 = {
  key: 0,
  class: "empty-msg-position"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "margin-top-40" }
const _hoisted_10 = ["indexFolder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationFolderListViewEditItemPane = _resolveComponent("NotificationFolderListViewEditItemPane")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass({'width25' : !_ctx.isMobileView})
          }, [
            _createElementVNode("button", {
              class: "btn btn-secondary btn-size-default",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.CloseFolder()))
            }, _toDisplayString(_ctx.$t("Button.Close")), 1)
          ], 2),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("CustomCategoryFolder.ManageFolders")), 1)
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "add-btn-position" }, null, -1))
        ]),
        ((_ctx.localFolderItems==null || _ctx.localFolderItems.length==0) && _ctx.localFolderItem==null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("p", null, [
                _createElementVNode("i", {
                  class: "font-size-14",
                  innerHTML: _ctx.$t('Dialogue.ManageFoldersEmptyScreenMessage')
                }, null, 8, _hoisted_8)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localFolderItems, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item,
              indexFolder: index,
              class: "margin-top-15"
            }, [
              (item != null)
                ? (_openBlock(), _createBlock(_component_NotificationFolderListViewEditItemPane, {
                    key: 0,
                    "selected-edit-folder-item": item,
                    "applicationborder-color": _ctx.loaderBorderColor
                  }, null, 8, ["selected-edit-folder-item", "applicationborder-color"]))
                : _createCommentVNode("", true)
            ], 8, _hoisted_10))
          }), 128))
        ])
      ])
    ])
  ]))
}