import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid-listing" }
const _hoisted_2 = { class: "margin-top-15" }
const _hoisted_3 = { class: "margin-top-15" }
const _hoisted_4 = { class: "edit-section" }
const _hoisted_5 = { class: "folder-details" }
const _hoisted_6 = { class: "item-position-from-image valign-parent50 folder-input" }
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = {
  key: 0,
  class: "error-vuelidate"
}
const _hoisted_9 = { class: "submit-position" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_loading, {
        "show-loading": _ctx.showLoading,
        "border-color": _ctx.loaderBorderColor
      }, null, 8, ["show-loading", "border-color"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "img-select" }, [
              _createElementVNode("div", { class: "edit-link" }, [
                _createElementVNode("i", {
                  class: "fa fa-folder-open",
                  style: {"font-size":"20px","padding-top":"17px"}
                })
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.localFolderItem.$model) = $event)),
                type: "text",
                class: _normalizeClass(["form-control valign width100", _ctx.status(_ctx.v$.localFolderItem)]),
                placeholder: _ctx.GetPlaceholder(),
                maxlength: "70"
              }, null, 10, _hoisted_7), [
                [
                  _vModelText,
                  _ctx.v$.localFolderItem.$model,
                  void 0,
                  { trim: true }
                ]
              ]),
              (_ctx.v$.localFolderItem.$error)
                ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t("Validation.NameRequired")), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("button", {
              class: _normalizeClass(["btn btn-primary btn-size-default", {'margin-left-15': !_ctx.isTabView}]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Submit(_ctx.v$)))
            }, _toDisplayString(_ctx.$t("SaveButton")), 3),
            _createElementVNode("button", {
              class: "btn btn-secondary btn-size-default margin-left-15",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Cancel(_ctx.v$)))
            }, _toDisplayString(_ctx.$t("CancelButton")), 1)
          ])
        ])
      ])
    ])
  ]))
}