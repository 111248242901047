import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modalpopup" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  class: "modal",
  "data-backdrop": "static",
  "data-keyboard": "false"
}
const _hoisted_4 = { class: "modal-dialog" }
const _hoisted_5 = { class: "modal-content" }
const _hoisted_6 = { class: "modal-header" }
const _hoisted_7 = { class: "modal-title" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationExportPane = _resolveComponent("NotificationExportPane")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.localExportShowPopup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_Transition, { name: "modal" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.$t("Dialogue.ExportHeaderContent")), 1),
                      _createElementVNode("a", {
                        href: "javascript:void(0)",
                        "aria-hidden": "true",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.Cancel();})
                      }, [
                        _createElementVNode("img", { src: _ctx.crossIcon }, null, 8, _hoisted_8)
                      ])
                    ]),
                    _cache[4] || (_cache[4] = _createElementVNode("hr", { class: "width100" }, null, -1)),
                    (_ctx.exportPopupVisible)
                      ? (_openBlock(), _createBlock(_component_NotificationExportPane, {
                          key: 0,
                          "participant-segments-export-pane": _ctx.localparticipantSegments,
                          "is-scrollable": true,
                          onExportTypeSelected: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onExportOptionsSelected($event)))
                        }, null, 8, ["participant-segments-export-pane"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn btn-secondary btn-size-default",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Cancel()))
                      }, _toDisplayString(_ctx.$t('Button.Cancel')), 1),
                      _createElementVNode("button", {
                        class: "btn btn-primary btn-size-default",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clickYes()))
                      }, _toDisplayString(_ctx.$t('Dialogue.Export')), 1)
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}