import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "div-empty-response-text"
}
const _hoisted_3 = { class: "empty-response-text-span" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HighchartWordCloud = _resolveComponent("HighchartWordCloud")!
  const _component_HighchartBarChart = _resolveComponent("HighchartBarChart")!
  const _component_HighchartPieChart = _resolveComponent("HighchartPieChart")!
  const _component_HighchartSpiderChart = _resolveComponent("HighchartSpiderChart")!

  return (_ctx.totalUsersFeedbacksCount > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.localNotificationItem.SelectedChartTypeId === _ctx.chartTypeEnum.WordCloud)
          ? (_openBlock(), _createBlock(_component_HighchartWordCloud, {
              key: 0,
              "chart-data": _ctx.localNotificationItem.Wordclouds
            }, null, 8, ["chart-data"]))
          : _createCommentVNode("", true),
        (_ctx.IsBarChart())
          ? (_openBlock(), _createBlock(_component_HighchartBarChart, {
              key: 1,
              "chart-data": _ctx.commonChartData
            }, null, 8, ["chart-data"]))
          : _createCommentVNode("", true),
        (_ctx.localNotificationItem.SelectedChartTypeId === _ctx.chartTypeEnum.PieChart)
          ? (_openBlock(), _createBlock(_component_HighchartPieChart, {
              key: 2,
              "chart-data": _ctx.commonChartData
            }, null, 8, ["chart-data"]))
          : _createCommentVNode("", true),
        (_ctx.localNotificationItem.SelectedChartTypeId === _ctx.chartTypeEnum.SpiderChart)
          ? (_openBlock(), _createBlock(_component_HighchartSpiderChart, {
              key: 3,
              "chart-data": _ctx.commonChartData
            }, null, 8, ["chart-data"]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("Notification.DialogueResults.ZeroResponses")), 1)
      ]))
}