import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  id: "pills-results-tab",
  class: "nav-link results-pill navigation-tab",
  "data-toggle": "pill",
  href: "#pills-results",
  role: "tab",
  "aria-controls": "pills-results",
  "aria-selected": "false"
}
const _hoisted_4 = { class: "display-inline-flex" }
const _hoisted_5 = {
  id: "pills-tabContent",
  class: "tab-content"
}
const _hoisted_6 = { class: "marin-top-20 margin-bottom-20" }
const _hoisted_7 = { class: "width100 inline-flex100" }
const _hoisted_8 = {
  for: "Description",
  class: "top-margin-in-fields width50"
}
const _hoisted_9 = { class: "color-red" }
const _hoisted_10 = {
  key: 0,
  class: "excel-icon width50"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "margin-left-3" }
const _hoisted_13 = {
  id: "content-div",
  class: "width100"
}
const _hoisted_14 = { id: "textarea-div" }
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = { class: "top-margin-in-fields" }
const _hoisted_17 = {
  id: "subItemsListView",
  class: "survey-listing-section top-margin-in-fields border-radius-10"
}
const _hoisted_18 = {
  id: "pills-results",
  class: "tab-pane fade margin-top-20",
  role: "tabpanel",
  "aria-labelledby": "pills-results-tab",
  style: {"opacity":"1"}
}
const _hoisted_19 = { key: 1 }
const _hoisted_20 = {
  key: 1,
  class: "right-container-margin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationSurveyItemsListView = _resolveComponent("NotificationSurveyItemsListView")!
  const _component_NotificationUserResponseOptions = _resolveComponent("NotificationUserResponseOptions")!
  const _component_NotificationFooterPane = _resolveComponent("NotificationFooterPane")!
  const _component_NotificationSurveyAllResults = _resolveComponent("NotificationSurveyAllResults")!
  const _component_NotificationSurveyNotificationItemEditPane = _resolveComponent("NotificationSurveyNotificationItemEditPane")!
  const _component_FileUpload = _resolveComponent("FileUpload")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.ShowSurveyNotificationEditDetailView())
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.IsEditMode)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("ul", {
                  id: "pills-tab",
                  class: _normalizeClass(["nav nav-pills center-pills", { 'showtabs': _ctx.localNotificationItem.Id > 0 && _ctx.notificationTypeId !== _ctx.notificationEnum.InformationMessage,'collapsetabs': _ctx.localNotificationItem.Id == 0 || _ctx.notificationTypeId == _ctx.notificationEnum.InformationMessage}]),
                  role: "tablist"
                }, [
                  _createElementVNode("li", {
                    class: _normalizeClass(["nav-item", { 'navaigation-pills-width': !_ctx.isMobileView, 'navaigation-pills-width-mobile': _ctx.isMobileView}])
                  }, [
                    _createElementVNode("a", {
                      id: "pills-settings-tab",
                      class: _normalizeClass(["nav-link active settings-pill navigation-tab", { 'active': _ctx.localNotificationItem.NotificationFeedbacks >= 0}]),
                      "data-toggle": "pill",
                      href: "#pills-settings",
                      role: "tab",
                      "aria-controls": "pills-settings",
                      "aria-selected": "true"
                    }, _toDisplayString(_ctx.$t("Settings")), 3)
                  ], 2),
                  _createElementVNode("li", {
                    class: _normalizeClass(["nav-item", { 'navaigation-pills-width': !_ctx.isMobileView, 'navaigation-pills-width-mobile': _ctx.isMobileView}])
                  }, [
                    _createElementVNode("a", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t("Dialogues.Results", {count: _ctx.localNotificationItem.NotificationFeedbacks})), 1)
                      ])
                    ])
                  ], 2)
                ], 2),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", {
                    id: "pills-settings",
                    class: _normalizeClass(["tab-pane fade show active", {'edit-pane': _ctx.localNotificationItem.NotificationFeedbacks >= 0}]),
                    role: "tabpanel",
                    "aria-labelledby": "pills-settings-tab"
                  }, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("label", _hoisted_8, [
                            _createTextVNode(_toDisplayString(_ctx.$t("Title")), 1),
                            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("Asteric")), 1)
                          ]),
                          (_ctx.localNotificationItem.NotificationFeedbacks > 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                _createElementVNode("img", {
                                  src: _ctx.excelIcon,
                                  class: "excel-icon-size margin-bottom-5"
                                }, null, 8, _hoisted_11),
                                _createElementVNode("a", {
                                  class: "hyperLink",
                                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ExportSurveyResults()))
                                }, [
                                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("ExportToExcel")), 1)
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("div", _hoisted_14, [
                            _withDirectives(_createElementVNode("textarea", {
                              id: "surveyTitle",
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localNotificationItem.Title) = $event)),
                              class: "form-control textarea-title textarea-size",
                              placeholder: _ctx.$t('Title'),
                              maxlength: "80"
                            }, null, 8, _hoisted_15), [
                              [
                                _vModelText,
                                _ctx.localNotificationItem.Title,
                                void 0,
                                { trim: true }
                              ]
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t("Dialogue.SurveyDescription")), 1),
                        _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                        _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t("Dialogue.SurveyRecommendation")), 1)
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_NotificationSurveyItemsListView, {
                          "notification-items-list": _ctx.virtualSurveySubNotificationList,
                          "notification-feedbacks": _ctx.localNotificationItem.NotificationFeedbacks,
                          "isusers-anonymous": _ctx.localNotificationItem.EnableResponseAnonymously,
                          "parent-notification-id": _ctx.localNotificationItem.Id,
                          onSetNotificationItemsSurvey: _cache[2] || (_cache[2] = ($event: any) => (_ctx.SetNotificationItemSurvey($event))),
                          onEditNotificationItemsSurvey: _ctx.EditingNotificationItemInSurvey
                        }, null, 8, ["notification-items-list", "notification-feedbacks", "isusers-anonymous", "parent-notification-id", "onEditNotificationItemsSurvey"])
                      ])
                    ]),
                    _createElementVNode("div", null, [
                      _createVNode(_component_NotificationUserResponseOptions, {
                        "user-response-options": _ctx.userResponseoptions,
                        onSetUserResponseOptions: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setUserResponseOptions($event)))
                      }, null, 8, ["user-response-options"])
                    ]),
                    _createElementVNode("div", null, [
                      _createVNode(_component_NotificationFooterPane, {
                        "is-create-new": _ctx.localNotificationItem.Id == 0 ? true : false,
                        folders: _ctx.folders,
                        folder: _ctx.localNotificationItem.Folder,
                        accesscode: _ctx.localNotificationItem.Code,
                        "filter-id": _ctx.localNotificationItem.FilterId,
                        "is-published": _ctx.localNotificationItem.IsPublished,
                        "scheduled-date": _ctx.localNotificationItem.ScheduleDate,
                        filters: _ctx.filters,
                        onSetGroup: _cache[4] || (_cache[4] = ($event: any) => (_ctx.SetSelectedFolder($event))),
                        onSetAccessCode: _cache[5] || (_cache[5] = ($event: any) => (_ctx.SetAccessCode($event))),
                        onSetFilter: _cache[6] || (_cache[6] = ($event: any) => (_ctx.SetFilter($event)))
                      }, null, 8, ["is-create-new", "folders", "folder", "accesscode", "filter-id", "is-published", "scheduled-date", "filters"])
                    ])
                  ], 2),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_NotificationSurveyAllResults, { "selected-notification-item": _ctx.localNotificationItem }, null, 8, ["selected-notification-item"])
                    ])
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createVNode(_component_NotificationSurveyNotificationItemEditPane, {
                  "selected-notification-item": Object.assign({}, _ctx.EditNotificationItemInSurvey),
                  "notification-items-list": _ctx.virtualSurveySubNotificationList,
                  "land-at-results": _ctx.islandAtResults
                }, null, 8, ["selected-notification-item", "notification-items-list", "land-at-results"])
              ]))
        ]))
      : _createCommentVNode("", true),
    (_ctx.ShowFileUploadSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
          _createVNode(_component_FileUpload, {
            "heading-title": _ctx.GetImageProcessHeadingTitle(),
            "image-src": _ctx.GetThumbnailImageUrl(),
            "image-full-src": _ctx.GetfeaturedimageFullUrl(),
            "accepted-file-types": _ctx.GetAcceptedFileTypes(),
            "file-extensions": _ctx.GetFileExtensions(),
            validationtype: _ctx.GetValidationType(),
            "icon-image-size-width": _ctx.GetIconImageSize(),
            "icon-image-size-height": _ctx.GetIconImageSize(),
            "validation-message": _ctx.GetValidationMessage(),
            "module-type": _ctx.moduleName,
            type: _ctx.GetImageType(),
            onSelectBack: _ctx.clickOnImage,
            onSetImageIconUrl: _ctx.SetImageIconUrl
          }, null, 8, ["heading-title", "image-src", "image-full-src", "accepted-file-types", "file-extensions", "validationtype", "icon-image-size-width", "icon-image-size-height", "validation-message", "module-type", "type", "onSelectBack", "onSetImageIconUrl"])
        ]))
      : _createCommentVNode("", true)
  ]))
}