import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vShow as _vShow, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, normalizeStyle as _normalizeStyle, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "item-listing" }
const _hoisted_2 = { class: "itemsDiv" }
const _hoisted_3 = { class: "toggling-container" }
const _hoisted_4 = { class: "toggling-left-container" }
const _hoisted_5 = { class: "folder-label-container" }
const _hoisted_6 = { class: "information-folder-label" }
const _hoisted_7 = { class: "large-display" }
const _hoisted_8 = { class: "small-display" }
const _hoisted_9 = { class: "folder-container" }
const _hoisted_10 = ["value"]
const _hoisted_11 = ["value"]
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "toggling-timezone-container" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  class: "information-folder-label",
  style: {"display":"inline-flex"}
}
const _hoisted_16 = {
  key: 0,
  class: "large-display"
}
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "time-zone-text" }
const _hoisted_19 = { class: "toggling-right-container" }
const _hoisted_20 = { class: "color-white" }
const _hoisted_21 = { class: "hyperLink margin-left-5" }
const _hoisted_22 = {
  class: "dropdown-toggle",
  "data-toggle": "dropdown",
  "aria-expanded": "[]"
}
const _hoisted_23 = ["src"]
const _hoisted_24 = ["src"]
const _hoisted_25 = {
  id: "status_Dropdown",
  class: "dropdown-menu square"
}
const _hoisted_26 = ["value", "onClick"]
const _hoisted_27 = { class: "dropdown-item" }
const _hoisted_28 = ["id", "onClick", "textContent"]
const _hoisted_29 = {
  key: 0,
  class: "empty-div"
}
const _hoisted_30 = {
  key: 0,
  class: "no-item-parent-div"
}
const _hoisted_31 = ["innerHTML"]
const _hoisted_32 = {
  key: 1,
  class: "no-item-parent-div"
}
const _hoisted_33 = ["innerHTML"]
const _hoisted_34 = {
  key: 2,
  class: "no-item-parent-div"
}
const _hoisted_35 = ["innerHTML"]
const _hoisted_36 = { class: "no-item-message-container" }
const _hoisted_37 = { class: "no-item-message" }
const _hoisted_38 = ["index", "disabled"]
const _hoisted_39 = ["onMouseover"]
const _hoisted_40 = { class: "main-body-container inline-flex100" }
const _hoisted_41 = ["src"]
const _hoisted_42 = {
  key: 0,
  class: "checkbox-container"
}
const _hoisted_43 = ["id", "value", "onClick"]
const _hoisted_44 = ["onClick"]
const _hoisted_45 = { style: {"width":"50px"} }
const _hoisted_46 = ["src"]
const _hoisted_47 = { class: "item-position-from-image valign-parent50" }
const _hoisted_48 = { class: "valign display-flex flex-direction-column" }
const _hoisted_49 = { class: "margin-top-10 display-flex notification-type-folder" }
const _hoisted_50 = { class: "folder-container-margin" }
const _hoisted_51 = { class: "margin-top-10" }
const _hoisted_52 = {
  key: 0,
  class: "time"
}
const _hoisted_53 = {
  key: 1,
  class: "time unpublished-item"
}
const _hoisted_54 = {
  key: 2,
  class: "time scheduled-item"
}
const _hoisted_55 = ["title"]
const _hoisted_56 = ["onClick"]
const _hoisted_57 = ["title"]
const _hoisted_58 = ["src"]
const _hoisted_59 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_loading = _resolveComponent("loading")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.modalPopupHeading,
          "body-heading": _ctx.modalPopupContentHeading,
          "body-content": _ctx.modalPopupContent,
          "show-cancel-button": _ctx.modalPopupShowCancelButton,
          "cancel-button-text": _ctx.modalPopupCancelButtonText,
          "confirm-button-text": _ctx.modalPopupConfirmButtonText,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-cancel-button", "cancel-button-text", "confirm-button-text", "show-close-button"]))
      : _createCommentVNode("", true),
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("Dialogue.FolderName")) + " :", 1),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("Dialogue.Folder")) + " :", 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _withDirectives(_createElementVNode("select", {
              id: "ddlstFolders",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedFolderName) = $event)),
              class: "form-control selectBox",
              onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.OnFolderChange($event.target.value)))
            }, [
              _createElementVNode("option", { value: _ctx.All }, _toDisplayString(_ctx.$t("Dialogue.All")), 9, _hoisted_10),
              _createElementVNode("option", { value: _ctx.TopLevel }, _toDisplayString(_ctx.$t("Dialogue.TopLevel")), 9, _hoisted_11),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationFolders, (folder, index) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: index,
                  value: folder
                }, _toDisplayString(folder), 9, _hoisted_12))
              }), 128))
            ], 544), [
              [_vModelSelect, _ctx.selectedFolderName]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          (_ctx.timeZones !== null && _ctx.timeZones !== '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("label", _hoisted_15, [
                  (_ctx.isMobileView == false)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.$t("Dialogue.Timezone")) + " : ", 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t("Dialogue.Tz")) + " :  ", 1)),
                  _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.timeZones), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_19, [
          _withDirectives(_createElementVNode("div", _hoisted_20, [
            _createElementVNode("span", _hoisted_21, [
              _createElementVNode("a", _hoisted_22, [
                (_ctx.selectedStatusOption === _ctx.All)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.filterIcon
                    }, null, 8, _hoisted_23))
                  : (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      src: _ctx.filterSelectedIcon
                    }, null, 8, _hoisted_24))
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("ul", _hoisted_25, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getStatusStrings(), (sts, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      value: sts,
                      onClick: ($event: any) => (_ctx.OnStatusOptionChange(sts))
                    }, [
                      _createElementVNode("a", _hoisted_27, [
                        _createElementVNode("label", null, [
                          _createElementVNode("input", {
                            id: 'nsi_' + sts,
                            type: "radio",
                            name: "statusCheckboxgroup",
                            onClick: ($event: any) => (_ctx.OnStatusOptionChange(sts)),
                            textContent: _toDisplayString(sts)
                          }, null, 8, _hoisted_28),
                          _createTextVNode(_toDisplayString(sts), 1)
                        ])
                      ])
                    ], 8, _hoisted_26))
                  }), 128))
                ])
              ])
            ])
          ], 512), [
            [_vShow, _ctx.notificationItems != null && _ctx.notificationItems.length > 0]
          ])
        ])
      ]),
      (
          _ctx.filteredNotificationItems == null ||
            _ctx.filteredNotificationItems.length == 0
        )
        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
            (
            _ctx.filteredNotificationItems.length == 0 &&
              _ctx.selectedStatusOption == _ctx.$t('Dialogue.PublishedLower')
          )
              ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                  _createElementVNode("h2", null, [
                    _createElementVNode("label", {
                      class: "no-item-font-weight font-size-30",
                      innerHTML: _ctx.$t('Dialogue.NoPublishedDialogueItem')
                    }, null, 8, _hoisted_31)
                  ])
                ]))
              : (
            _ctx.filteredNotificationItems.length == 0 &&
              _ctx.selectedStatusOption == _ctx.$t('Dialogue.UnpublishedLower')
          )
                ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                    _createElementVNode("h2", null, [
                      _createElementVNode("label", {
                        class: "no-item-font-weight font-size-30",
                        innerHTML: _ctx.$t('Dialogue.NoUnpublishedDialogueItem')
                      }, null, 8, _hoisted_33)
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_34, [
                    _createElementVNode("h2", null, [
                      _createElementVNode("label", {
                        class: "no-item-font-weight font-size-30",
                        innerHTML: _ctx.$t('Dialogue.LandingPageNoDialogueItem')
                      }, null, 8, _hoisted_35)
                    ])
                  ])),
            _createElementVNode("div", _hoisted_36, [
              _createElementVNode("span", _hoisted_37, [
                _createTextVNode(_toDisplayString(_ctx.$t("StartBytext")) + " ", 1),
                _createElementVNode("a", {
                  class: "hyperLink questionLinkFont",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.OpenCreateNewDailoguepane()))
                }, _toDisplayString(_ctx.$t("Dialogue.CreateNewDialogueLink")), 1)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createVNode(_component_draggable, {
          id: "draggable",
          list: _ctx.filteredNotificationItems,
          tag: "ul",
          class: "list-group",
          animation: "500",
          handle: ".handle",
          onStart: _cache[6] || (_cache[6] = ($event: any) => (_ctx.draggingStart($event, { passive: false }))),
          onEnd: _cache[7] || (_cache[7] = ($event: any) => (_ctx.draggingEnd($event, { passive: false })))
        }, {
          default: _withCtx(() => [
            _createVNode(_TransitionGroup, { type: "transition" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredNotificationItems, (notificationItem, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: notificationItem.Id,
                    index: index,
                    class: _normalizeClass(["item-default-bg-color", [notificationItem.DisableItem ? '' : 'cursor-pointer']]),
                    disabled: notificationItem.DisableItem,
                    style: _normalizeStyle([notificationItem.DisableItem == true ? { 'pointer-events': 'none', 'opacity': '0.4' }: ''])
                  }, [
                    _withDirectives(_createElementVNode("div", {
                      class: "main-item-div",
                      style: _normalizeStyle({
                  'background-color': (notificationItem.StyleDefaultDiv),
                }),
                      onMouseover: ($event: any) => (_ctx.ShowDragAndDropIcon(index)),
                      onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.ShowDragAndDropIcon(null)))
                    }, [
                      _createElementVNode("div", _hoisted_40, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["valign-parent40 drag-drop-icon cursor-move handle", {'visibility-hidden': (_ctx.hoverItemIndex !== index && !_ctx.isTabView)}])
                        }, [
                          _createElementVNode("img", { src: _ctx.dragDropIcon }, null, 8, _hoisted_41)
                        ], 2),
                        (_ctx.isMobileView == false)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                              _withDirectives(_createElementVNode("input", {
                                id: 'ni_' + notificationItem.Id,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedItems) = $event)),
                                type: "checkbox",
                                class: "noItemDisplay",
                                value: notificationItem,
                                onClick: ($event: any) => (
                        _ctx.CheckNotificationItems(notificationItem, index, $event)
                      )
                              }, null, 8, _hoisted_43), [
                                [_vModelCheckbox, _ctx.selectedItems]
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("a", {
                          href: "javascript:void(0)",
                          class: "inline-flex100 ipad_tag",
                          style: _normalizeStyle({
                      'color': _ctx.SetFontColor(notificationItem.Id, true),
                    }),
                          onClick: ($event: any) => (_ctx.SelectItem(notificationItem))
                        }, [
                          _createElementVNode("div", _hoisted_45, [
                            _createElementVNode("img", {
                              src: _ctx.GetImageUrl(
                          notificationItem.NotificationTypeId
                        )
                            }, null, 8, _hoisted_46)
                          ]),
                          _createElementVNode("div", _hoisted_47, [
                            _createElementVNode("div", _hoisted_48, [
                              _createElementVNode("span", {
                                class: "description-heading",
                                style: _normalizeStyle({'color': _ctx.SetFontColor(notificationItem.Id, true)})
                              }, _toDisplayString(notificationItem.Title), 5),
                              _createElementVNode("span", _hoisted_49, [
                                _createElementVNode("div", _hoisted_50, [
                                  _cache[8] || (_cache[8] = _createElementVNode("i", { class: "fa fa-folder-open" }, null, -1)),
                                  (notificationItem.Folder)
                                    ? (_openBlock(), _createElementBlock("span", {
                                        key: 0,
                                        class: "margin-left4 secondary-color",
                                        style: _normalizeStyle({'color': _ctx.SetFontColor(notificationItem.Id, false)})
                                      }, _toDisplayString(notificationItem.Folder), 5))
                                    : (_openBlock(), _createElementBlock("span", {
                                        key: 1,
                                        class: "margin-left4 secondary-color",
                                        style: _normalizeStyle({'color': _ctx.SetFontColor(notificationItem.Id, false)})
                                      }, _toDisplayString(_ctx.$t("Dialogue.TopLevel")), 5))
                                ]),
                                _cache[10] || (_cache[10] = _createElementVNode("br", { class: "mobile-break" }, null, -1)),
                                _createElementVNode("div", {
                                  class: "",
                                  style: _normalizeStyle({'color': _ctx.SetFontColor(notificationItem.Id, false)})
                                }, [
                                  _cache[9] || (_cache[9] = _createElementVNode("i", { class: "fa fa-users" }, null, -1)),
                                  (notificationItem.Filter)
                                    ? (_openBlock(), _createElementBlock("span", {
                                        key: 0,
                                        class: "margin-left4 secondary-color",
                                        style: _normalizeStyle({'color': _ctx.SetFontColor(notificationItem.Id, false)})
                                      }, _toDisplayString(notificationItem.Filter), 5))
                                    : (_openBlock(), _createElementBlock("span", {
                                        key: 1,
                                        class: "margin-left4 secondary-color",
                                        style: _normalizeStyle({'color': _ctx.SetFontColor(notificationItem.Id, false)})
                                      }, _toDisplayString(_ctx.$t("Dialogue.EveryoneFilter")), 5))
                                ], 4)
                              ]),
                              _createElementVNode("span", _hoisted_51, [
                                (notificationItem.IsPublished)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_52, [
                                      _createTextVNode(_toDisplayString(_ctx.$t("PublishedWithHyphen")) + " ", 1),
                                      _createElementVNode("span", {
                                        class: "font-weight-normal secondary-color publish-date",
                                        style: _normalizeStyle({'color': _ctx.SetFontColor(notificationItem.Id, false)})
                                      }, _toDisplayString(_ctx.formatTime(notificationItem)), 5)
                                    ]))
                                  : (
                              notificationItem.IsPublished == false &&
                                notificationItem.ScheduleDate == null
                            )
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_53, _toDisplayString(_ctx.$t("Dialogue.Unpublished")), 1))
                                    : (
                              notificationItem.IsPublished == false &&
                                notificationItem.ScheduleDate
                            )
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_54, [
                                          _createTextVNode(_toDisplayString(_ctx.$t("Dialogue.Scheduled")) + " ", 1),
                                          _createElementVNode("span", null, _toDisplayString(_ctx.formatScheduleTime(notificationItem, false)), 1)
                                        ]))
                                      : _createCommentVNode("", true)
                              ])
                            ])
                          ]),
                          _createElementVNode("div", {
                            class: _normalizeClass(["notification-response-badge", {'visibility-hidden': notificationItem.NotificationTypeId === 0}])
                          }, [
                            _createElementVNode("span", {
                              class: "badge badge-style",
                              title: _ctx.IconToolTip(_ctx.$t('Dialogue.NumberOfResponsesIcon')),
                              style: _normalizeStyle({'color': _ctx.SetFontColor(notificationItem.Id, false)})
                            }, _toDisplayString(notificationItem.NotificationFeedbacks), 13, _hoisted_55)
                          ], 2),
                          _createElementVNode("div", {
                            class: "valign-parent50",
                            onClick: ($event: any) => (_ctx.CopyNotification(notificationItem))
                          }, [
                            _createElementVNode("span", {
                              title: _ctx.IconToolTip(_ctx.$t('Dialogue.CopyIcon'))
                            }, [
                              (_ctx.SetCopyIcon(notificationItem.Id))
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    src: _ctx.selectedCopyIcon
                                  }, null, 8, _hoisted_58))
                                : (_openBlock(), _createElementBlock("img", {
                                    key: 1,
                                    src: _ctx.copyIcon
                                  }, null, 8, _hoisted_59))
                            ], 8, _hoisted_57)
                          ], 8, _hoisted_56)
                        ], 12, _hoisted_44)
                      ])
                    ], 44, _hoisted_39), [
                      [_vShow, !notificationItem.IsDeleted]
                    ])
                  ], 14, _hoisted_38))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["list"])
      ])
    ])
  ]))
}