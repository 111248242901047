import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "div-pptplugin-token top-margin-in-fields" }
const _hoisted_2 = { class: "p-ppt-heading description-heading" }
const _hoisted_3 = {
  key: 0,
  class: "alert alert-success margin-top-20"
}
const _hoisted_4 = { class: "p-ppt-description" }
const _hoisted_5 = { class: "div-token-generate-area" }
const _hoisted_6 = { class: "div-generate-token" }
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = ["src", "title"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "font-style-italic" }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { class: "p-ppt-description" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "margin-left-right-15" }
const _hoisted_15 = { class: "p-ppt-description" }
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "p-ppt-description" }
const _hoisted_18 = ["src"]
const _hoisted_19 = {
  key: 3,
  class: "margin-left-right-15"
}
const _hoisted_20 = { class: "p-ppt-description" }
const _hoisted_21 = { class: "div-generate-token" }
const _hoisted_22 = { class: "div-generate-token-steps" }
const _hoisted_23 = { class: "no-margin" }
const _hoisted_24 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueModalPopUp = _resolveComponent("VueModalPopUp")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showModalPopup)
      ? (_openBlock(), _createBlock(_component_VueModalPopUp, {
          key: 0,
          "modal-pop-up": _ctx.revokeTokenModelPopup,
          "is-content-left-aligned": true,
          onClose: _ctx.CloseModelPopup,
          onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.SaveModelPopUp($event)))
        }, null, 8, ["modal-pop-up", "onClose"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("Notification.PowerPointIntegrationHeading")), 1),
      (_ctx.showKeyCopiedMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[6] || (_cache[6] = _createElementVNode("i", {
              class: "fa fa-check",
              "aria-hidden": "true"
            }, null, -1)),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("Notification.PowerPointIntegration.TokenCopied")), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("Notification.PowerPointIntegrationDescription")), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localPowerPointTokenToken.Token) = $event)),
            class: "input-token-area",
            type: "text",
            placeholder: _ctx.$t('Notification.PowerPointIntegration.TokenPlaceholder'),
            disabled: ""
          }, null, 8, _hoisted_7), [
            [_vModelText, _ctx.localPowerPointTokenToken.Token]
          ]),
          (_ctx.visibleRevokeToken && !_ctx.visibleTokenExpired)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.copyIcon,
                class: "icon-copy-token",
                title: _ctx.$t('Dialogue.CopyIcon'),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.copyLinkToClipboard(_ctx.localPowerPointTokenToken.Token)))
              }, null, 8, _hoisted_8))
            : _createCommentVNode("", true),
          (_ctx.visibleTokenGenerated && _ctx.visibleRevokeToken && !_ctx.visibleTokenExtended && !_ctx.visibleTokenExpired)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, [
                  _createTextVNode(_toDisplayString(_ctx.$t("Notification.PowerPointIntegration.TokenWillExpired")) + " ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.expirationDate), 1)
                ]),
                _createElementVNode("span", {
                  class: "margin-left-5 hyperLink font-weight-600 display-inline-block",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ExtendUniqueTokenDate(_ctx.localPowerPointTokenToken.Id)))
                }, _toDisplayString(_ctx.$t("Notification.PowerPointIntegration.ExtendDate")), 1)
              ]))
            : (!_ctx.visibleTokenGenerated && _ctx.visibleRevokeToken)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("Notification.PowerPointIntegration.TokenGenerated")), 1),
                  _createElementVNode("img", { src: _ctx.greenTickIcon }, null, 8, _hoisted_13)
                ]))
              : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("div", _hoisted_14, [
            _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("Notification.PowerPointIntegration.TokenRevoked")), 1),
            _createElementVNode("img", { src: _ctx.redCrossIcon }, null, 8, _hoisted_16)
          ], 512), [
            [_vShow, _ctx.visibleTokenRevoked]
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("Notification.PowerPointIntegration.ExpiryDateExtend")), 1),
            _createElementVNode("img", { src: _ctx.greenTickIcon }, null, 8, _hoisted_18)
          ], 512), [
            [_vShow, _ctx.visibleTokenExtended]
          ]),
          (_ctx.visibleTokenExpired)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t("Notification.PowerPointIntegration.TokenExpired")), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_21, [
          (!_ctx.visibleRevokeToken || _ctx.visibleTokenExpired )
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(["hyperLink font-weight-600 span-generate-token", {disabled: _ctx.visibleTokenRevoked}]),
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.GenerateUniqueToken(_ctx.localNotificationItemId)))
              }, _toDisplayString(_ctx.$t("Notification.PowerPointIntegration.GenerateUniqueToken")), 3))
            : (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: "font-weight-600 span-revoke-token",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.RevokeUniqueToken()))
              }, _toDisplayString(_ctx.$t("Notification.PowerPointIntegration.RevokeToken")), 1))
        ])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.$t("Notification.PowerPointIntegration.GenerateTokenSteps")), 1),
        _createElementVNode("ol", {
          class: "padding-left-15",
          innerHTML: _ctx.$t('Notification.PowerPointIntegration.GenerateTokenInstructions')
        }, null, 8, _hoisted_24)
      ])
    ])
  ]))
}