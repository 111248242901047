import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "margin-bottom-20" }
const _hoisted_2 = { class: "editPane scroll-top" }
const _hoisted_3 = { class: "no-margin" }
const _hoisted_4 = { class: "addtwooptionsmsg no-margin" }
const _hoisted_5 = { class: "color-red" }
const _hoisted_6 = ["index"]
const _hoisted_7 = {
  key: 0,
  class: "valign-parent50 cursor-move handle padding-right-10"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = ["id", "onUpdate:modelValue", "placeholder", "onInput", "onChange"]
const _hoisted_10 = ["onClick", "onTouchstart"]
const _hoisted_11 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", null, [
          _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.mainHeading), 1),
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.subHeading), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("Asteric")), 1)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_draggable, {
            list: _ctx.localnotificationOptions,
            tag: "ul",
            class: "list-group",
            animation: "500",
            handle: ".handle",
            options: {disabled: !_ctx.isDraggable},
            onStart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.draggingStart($event, { passive: false }))),
            onEnd: _cache[1] || (_cache[1] = ($event: any) => (_ctx.draggingEnd($event, { passive: false })))
          }, {
            default: _withCtx(() => [
              _createVNode(_TransitionGroup, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localnotificationOptions, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: item.Id,
                      class: "margin-top-10",
                      index: index
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass([{ mobileViewOptionsWidth: _ctx.isMobileView, width100: !_ctx.isMobileView }, "inline-flex align-items-center"])
                      }, [
                        (_ctx.isDraggable)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createElementVNode("img", { src: _ctx.dragDropIcon }, null, 8, _hoisted_8)
                            ]))
                          : _createCommentVNode("", true),
                        _withDirectives(_createElementVNode("input", {
                          id: item.Id,
                          ref_for: true,
                          ref: 'responseOptionName' + index,
                          "onUpdate:modelValue": ($event: any) => ((item.Text) = $event),
                          style: _normalizeStyle(_ctx.GetTextboxStyling(item)),
                          type: "text",
                          placeholder: _ctx.optionplaceHolder,
                          class: "form-control",
                          maxlength: "128",
                          onInput: ($event: any) => (_ctx.CheckIfEmptyOption(item, index)),
                          onChange: ($event: any) => (_ctx.CheckIfEmptyOption(item, index))
                        }, null, 44, _hoisted_9), [
                          [_vModelText, item.Text]
                        ]),
                        _createElementVNode("button", {
                          class: "btn btn-delete",
                          onClick: ($event: any) => (_ctx.RemoveOption(item)),
                          onTouchstart: ($event: any) => (_ctx.RemoveOption(item))
                        }, [
                          _createElementVNode("img", { src: _ctx.deleteIcon }, null, 8, _hoisted_11)
                        ], 40, _hoisted_10)
                      ], 2)
                    ], 8, _hoisted_6))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["list", "options"])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("a", {
            class: _normalizeClass(["hyperLink", {disabled: _ctx.IsCreateNewLinkDisabled}]),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.AddNewOption()))
          }, _toDisplayString(_ctx.createNew), 3)
        ])
      ])
    ])
  ]))
}