import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "response-reminder-div border-radius-10" }
const _hoisted_2 = { class: "width50" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "div-send-reminder"
}
const _hoisted_5 = ["title"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "hyperLink div-remove-all-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_VueModalPopUp = _resolveComponent("VueModalPopUp")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.$t('Dialogue.ClearAllResponses'),
          "body-heading": _ctx.modalPopupHeading,
          "body-content": _ctx.modalPopupContent,
          "show-close-button": _ctx.showCloseButton,
          "show-cancel-button": _ctx.modalPopupShowCancelButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button", "show-cancel-button"]))
      : _createCommentVNode("", true),
    (_ctx.showVueModalPopUp)
      ? (_openBlock(), _createBlock(_component_VueModalPopUp, {
          key: 1,
          "modal-pop-up": _ctx.sendReminderPopUpContent,
          "is-content-left-aligned": true,
          onClose: _ctx.CloseVueModelPopup,
          onSave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.SendReminderToParticipants($event)))
        }, null, 8, ["modal-pop-up", "onClose"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "span-response-count",
        innerHTML: _ctx.GetText()
      }, null, 8, _hoisted_3),
      (!_ctx.IsReminderLinkVisible())
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("span", {
              class: _normalizeClass([[_ctx.IsSendReminderLinkDisabled() ? 'cursor-pointer' : 'disable-click'], "span-send-reminder"]),
              title: _ctx.ShowToolTip(),
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.ShowReminderPopUp && _ctx.ShowReminderPopUp(...args)))
            }, _toDisplayString(_ctx.$t('Dialogue.SendReminderToRespond')), 11, _hoisted_5),
            _createElementVNode("span", {
              class: "span-reminder-count small-description color-grey",
              innerHTML: _ctx.GetTotalCountOfReminder()
            }, null, 8, _hoisted_6)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("span", {
        class: _normalizeClass(["cursor-pointer font-weight-600", { 'disable-click': _ctx.IsClearAllResponsesDisabled()}]),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ClearAllResponseClicked()))
      }, _toDisplayString(_ctx.$t("Dialogue.HighCharts.ClearAllResponses")), 3)
    ])
  ]))
}