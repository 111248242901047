import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { id: "subnotificationEditMode" }
const _hoisted_2 = { class: "margin-top-20 margin-bottom-20" }
const _hoisted_3 = { class: "inline-flex100 align-items-center" }
const _hoisted_4 = { class: "main-title-width" }
const _hoisted_5 = {
  key: 0,
  class: "main-heading primary-heading"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "main-heading primary-heading" }
const _hoisted_8 = {
  key: 0,
  class: "inline-flex"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "margin-validation" }
const _hoisted_12 = {
  key: 0,
  id: "addItemMsgDiv",
  class: "alert alert-success"
}
const _hoisted_13 = { class: "nav-item navaigation-pills-width" }
const _hoisted_14 = { class: "nav-item navaigation-pills-width" }
const _hoisted_15 = {
  id: "pills-tabContent",
  class: "tab-content"
}
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { key: 2 }
const _hoisted_19 = { key: 3 }
const _hoisted_20 = { key: 4 }
const _hoisted_21 = { key: 5 }
const _hoisted_22 = { key: 6 }
const _hoisted_23 = {
  key: 7,
  class: "margin-top-20 margin-bottom-20"
}
const _hoisted_24 = { class: "inline-flex description" }
const _hoisted_25 = { class: "padding-1 margin-left-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!
  const _component_NotificationInformationMessageEditPane = _resolveComponent("NotificationInformationMessageEditPane")!
  const _component_NotificationSingleChoiceQuestionEditPane = _resolveComponent("NotificationSingleChoiceQuestionEditPane")!
  const _component_NotificationMultipleChoiceQuestionEditPane = _resolveComponent("NotificationMultipleChoiceQuestionEditPane")!
  const _component_NotificationSingleResponseTextFeedbackEditPane = _resolveComponent("NotificationSingleResponseTextFeedbackEditPane")!
  const _component_NotificationPickCorrectAnswerEditPane = _resolveComponent("NotificationPickCorrectAnswerEditPane")!
  const _component_NotificationRankOptionItemEditPane = _resolveComponent("NotificationRankOptionItemEditPane")!
  const _component_NotificationScaleStatementEditPane = _resolveComponent("NotificationScaleStatementEditPane")!
  const _component_NotificationResultsDetails = _resolveComponent("NotificationResultsDetails")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            class: "btn btn-secondary btn-size-default",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Cancel()))
          }, _toDisplayString(_ctx.$t("Button.Close")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.isMobileView)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t("Dialogue.DialogueItem")), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("Dialogue.DialogueItem")), 1)
              ]))
        ]),
        (_ctx.NavigatorButtonsVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (!_ctx.localNotificationItem.IsNewSurveyItem )
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("button", {
                      class: "btn btn-primary btn-size-default",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Submit(_ctx.v$)))
                    }, _toDisplayString(_ctx.$t("Dialogue.Save")), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("button", {
                      class: "btn btn-primary btn-size-default",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Submit(_ctx.v$)))
                    }, _toDisplayString(_ctx.$t("Button.Add")), 1)
                  ]))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_11, [
        (_ctx.visibleValidationSummary)
          ? (_openBlock(), _createBlock(_component_ValidationSummary, {
              key: 0,
              "error-fields": _ctx.validationErrorFields,
              onCloseClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.CloseValidationSummary($event)))
            }, null, 8, ["error-fields"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.showAddedMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _cache[9] || (_cache[9] = _createElementVNode("i", {
                  class: "fa fa-check",
                  "aria-hidden": "true"
                }, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("Dialogue.ItemAddedSuccess", { title: _ctx.addedItemTitle })), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", {
        class: _normalizeClass(["survey-edit-controllers", { 'showtabs': _ctx.localNotificationItem.Id >= 0 && _ctx.localNotificationItem.IsPublished,'collapsetabs': _ctx.localNotificationItem.IsNewSurveyItem }])
      }, [
        _createElementVNode("div", null, [
          (_ctx.NavigatorButtonsVisible)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: _normalizeClass({'disabled-navigation-button' : !_ctx.isPreviousButtonsVisible}),
                href: "javascript:void(0)",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.Previous()))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass([{'disabled-navigation-arrow' : !_ctx.isPreviousButtonsVisible}, "arrow left visible-arrows"])
                }, null, 2),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("Button.Previous")), 1)
              ], 2))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("ul", {
          id: "pills-tab",
          class: _normalizeClass([{'width100' : _ctx.showResults, 'add-item-invisible-tabs' : _ctx.localNotificationItem.Id < 0}, "nav nav-pills center-pills survey-edit-pills"]),
          role: "tablist"
        }, [
          _createElementVNode("li", _hoisted_13, [
            _createElementVNode("a", {
              id: "pills-settings-tab",
              class: _normalizeClass(["nav-link settings-pill", {'active': !_ctx.showResults}]),
              "data-toggle": "pill",
              href: "#pills-settings",
              role: "tab",
              "aria-controls": "pills-settings",
              "aria-selected": "true",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.ShowNaigators(true)))
            }, _toDisplayString(_ctx.$t("Settings")), 3)
          ]),
          _createElementVNode("li", _hoisted_14, [
            _createElementVNode("a", {
              id: "pills-results-tab",
              class: _normalizeClass(["nav-link results-pill", {'active': _ctx.showResults, 'deactivated-results-tab': _ctx.localNotificationItem.NotificationTypeId == 0}]),
              "data-toggle": "pill",
              href: "#pills-results",
              role: "tab",
              "aria-controls": "pills-results",
              "aria-selected": "false",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.ShowNaigators(false)))
            }, _toDisplayString(_ctx.$t("Dialogues.Results", {count: _ctx.localNotificationItem.NotificationFeedbacks})), 3)
          ])
        ], 2),
        _createElementVNode("div", null, [
          (_ctx.NavigatorButtonsVisible)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: _normalizeClass({'disabled-navigation-button' : !_ctx.isNextButtonsVisible}),
                href: "javascript:void(0)",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.Next()))
              }, [
                _createTextVNode(_toDisplayString(_ctx.$t("Button.Next")) + " ", 1),
                _createElementVNode("i", {
                  class: _normalizeClass([{'disabled-navigation-arrow' : !_ctx.isNextButtonsVisible}, "arrow right visible-arrows"])
                }, null, 2)
              ], 2))
            : _createCommentVNode("", true)
        ])
      ], 2),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", {
          id: "pills-settings",
          class: _normalizeClass(["tab-pane fade", {'active': !_ctx.showResults,
                   'create-pane' : _ctx.localNotificationItem.NotificationFeedbacks == 0,
                   'edit-pane': _ctx.localNotificationItem.NotificationFeedbacks > 0}]),
          role: "tabpanel",
          "aria-labelledby": "pills-settings-tab"
        }, [
          (_ctx.notificationTypeId === _ctx.notificationEnum.InformationMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createVNode(_component_NotificationInformationMessageEditPane, {
                  "selected-notification-item": _ctx.localNotificationItem,
                  folders: _ctx.folders
                }, null, 8, ["selected-notification-item", "folders"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.notificationTypeId === _ctx.notificationEnum.SingleChoiceQuestion)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createVNode(_component_NotificationSingleChoiceQuestionEditPane, {
                  "selected-notification-item": _ctx.localNotificationItem,
                  folders: _ctx.folders
                }, null, 8, ["selected-notification-item", "folders"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.notificationTypeId === _ctx.notificationEnum.MultipleChoiceQuestion)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createVNode(_component_NotificationMultipleChoiceQuestionEditPane, {
                  "selected-notification-item": _ctx.localNotificationItem,
                  folders: _ctx.folders
                }, null, 8, ["selected-notification-item", "folders"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.notificationTypeId === _ctx.notificationEnum.SingleRespponseTextFeedback)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createVNode(_component_NotificationSingleResponseTextFeedbackEditPane, {
                  "selected-notification-item": _ctx.localNotificationItem,
                  folders: _ctx.folders
                }, null, 8, ["selected-notification-item", "folders"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.notificationTypeId === _ctx.notificationEnum.PickCorrect)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _createVNode(_component_NotificationPickCorrectAnswerEditPane, {
                  "selected-notification-item": _ctx.localNotificationItem,
                  folders: _ctx.folders
                }, null, 8, ["selected-notification-item", "folders"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.notificationTypeId === _ctx.notificationEnum.RankOptionItems)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createVNode(_component_NotificationRankOptionItemEditPane, {
                  "selected-notification-item": _ctx.localNotificationItem,
                  folders: _ctx.folders
                }, null, 8, ["selected-notification-item", "folders"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.notificationTypeId === _ctx.notificationEnum.ScaleStatements)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _createVNode(_component_NotificationScaleStatementEditPane, {
                  "selected-notification-item": _ctx.localNotificationItem,
                  folders: _ctx.folders
                }, null, 8, ["selected-notification-item", "folders"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.localNotificationItem.IsNewSurveyItem)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createElementVNode("label", _hoisted_24, [
                  _withDirectives(_createElementVNode("input", {
                    id: "allowNoResponse",
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localNotificationItem.creatAnother) = $event)),
                    type: "checkbox"
                  }, null, 512), [
                    [_vModelCheckbox, _ctx.localNotificationItem.creatAnother]
                  ]),
                  _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t("Dialogue.CreatingAnotherSurveyItem")), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ], 2),
        (!_ctx.localNotificationItem.IsCreateNewItemInSurvey)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              id: "pills-results",
              class: _normalizeClass(["tab-pane fade right-container-margin", { 'active': _ctx.showResults}]),
              role: "tabpanel",
              "aria-labelledby": "pills-results-tab",
              style: {"opacity":"1"}
            }, [
              _createElementVNode("div", null, [
                _createVNode(_component_NotificationResultsDetails, { "selected-notification-item": _ctx.localNotificationItem }, null, 8, ["selected-notification-item"])
              ])
            ], 2))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}