import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid-listing" }
const _hoisted_2 = {
  key: 1,
  class: "edit-section"
}
const _hoisted_3 = { class: "item-position-from-image valign-parent50 width100" }
const _hoisted_4 = { class: "valign wrap-span" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 2,
  id: "notificationFolderItemEditPane",
  class: "margin-top-15"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_NotificationFolderEditItemPane = _resolveComponent("NotificationFolderEditItemPane")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.$t('DeleteFolder'),
          "body-heading": _ctx.modalPopupHeading,
          "body-content": _ctx.modalPopupContent,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button"]))
      : _createCommentVNode("", true),
    (_ctx.localFolderItem && !_ctx.showEditFolder)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            style: _normalizeStyle(_ctx.getLeftDivStyle(_ctx.isMobileView)),
            class: "display-flex"
          }, [
            _cache[5] || (_cache[5] = _createElementVNode("span", null, [
              _createElementVNode("i", {
                class: "fa fa-folder-open",
                style: {"font-size":"20px","padding-top":"17px"}
              })
            ], -1)),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.localFolderItem), 1)
            ])
          ], 4),
          _createElementVNode("div", {
            style: _normalizeStyle(_ctx.getRightDivStyle(_ctx.isMobileView)),
            class: "right-container-btn"
          }, [
            _createElementVNode("button", {
              class: _normalizeClass(["btn btn-secondary valign", [_ctx.isMobileView ==true ? '' : 'btn-size-default']]),
              style: _normalizeStyle(_ctx.getEditButtonstyle(_ctx.isMobileView)),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectItem(_ctx.localFolderItem)))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("Button.Edit")), 1)
            ], 6),
            _createElementVNode("div", {
              class: "btn btn-delete valign",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.DeleteSelectedFolder(_ctx.localFolderItem)))
            }, [
              _createElementVNode("img", { src: _ctx.deleteIcon }, null, 8, _hoisted_5)
            ])
          ], 4)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showEditFolder)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_NotificationFolderEditItemPane, {
            "selected-folder-item": _ctx.localFolderItem,
            "applicationborder-color": _ctx.loaderBorderColor,
            onNotificationFolderItemCancelOperation: _cache[3] || (_cache[3] = ($event: any) => (_ctx.NotificationFolderItemCancelOperation($event))),
            onNotificationFolderItemEditUpdateOperation: _cache[4] || (_cache[4] = ($event: any) => (_ctx.NotificationFolderItemEditedUpdatedOperation($event)))
          }, null, 8, ["selected-folder-item", "applicationborder-color"])
        ]))
      : _createCommentVNode("", true)
  ]))
}