import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "inline-flex100" }
const _hoisted_2 = { class: "inline-flex100" }
const _hoisted_3 = { class: "width50" }
const _hoisted_4 = { class: "font-size-16" }
const _hoisted_5 = { class: "color-red" }
const _hoisted_6 = { class: "width50" }
const _hoisted_7 = { class: "hyperLink inline-flex" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "add-sub-dialogue-text" }
const _hoisted_10 = {
  key: 0,
  class: "ajax-loader"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "description" }
const _hoisted_15 = {
  key: 1,
  class: "grid-listing sub-dialoge-listing"
}
const _hoisted_16 = ["onMouseover"]
const _hoisted_17 = { class: "valign-parent50 cursor-move handle handle-padding" }
const _hoisted_18 = ["src"]
const _hoisted_19 = { style: {"width":"50px","margin-top":"5px"} }
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "question-format dialogue-info-width" }
const _hoisted_22 = { class: "valign" }
const _hoisted_23 = { class: "time description-heading" }
const _hoisted_24 = { class: "valign" }
const _hoisted_25 = { class: "manage-div-width manage-button visible-manage-button" }
const _hoisted_26 = { class: "dropdown inline-flex valign" }
const _hoisted_27 = { "data-toggle": "dropdown" }
const _hoisted_28 = ["src"]
const _hoisted_29 = { class: "dropdown-menu" }
const _hoisted_30 = ["onClick"]
const _hoisted_31 = ["onClick"]
const _hoisted_32 = ["onClick"]
const _hoisted_33 = { class: "dropdown visible-manage-kabab" }
const _hoisted_34 = {
  "data-toggle": "dropdown",
  class: "three-dots margin-top-10",
  style: {"display":"inline-flex"}
}
const _hoisted_35 = { class: "dropdown-menu" }
const _hoisted_36 = ["onClick"]
const _hoisted_37 = ["onClick"]
const _hoisted_38 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, [
              _createTextVNode(_toDisplayString(_ctx.$t("Dialogue.IncludedNotificationItems")), 1),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("Asteric")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _createElementVNode("div", {
                class: "add-sub-dialogue-div",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickOnAddSurveyItem(true)))
              }, [
                _createElementVNode("a", _hoisted_7, [
                  _createElementVNode("img", {
                    src: _ctx.addIcon,
                    class: "margin-right-10"
                  }, null, 8, _hoisted_8),
                  _createElementVNode("span", _hoisted_9, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("Dialogue.AddItem")), 1)
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      (_ctx.localNotificationLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("img", {
              class: "img-responsive ajax-loader-img",
              src: _ctx.loadingImage,
              alt: "Wait"
            }, null, 8, _hoisted_11)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.localNotificationLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            (_ctx.localNotificationItems.length == 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("Dialogue.NoItemsAdded")), 1)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_draggable, {
                      list: _ctx.localNotificationItems,
                      tag: "ul",
                      class: "list-group",
                      animation: "500",
                      move: _ctx.checkMove,
                      handle: ".handle",
                      onStart: _cache[2] || (_cache[2] = ($event: any) => (_ctx.draggingStart($event, { passive: false }))),
                      onEnd: _cache[3] || (_cache[3] = ($event: any) => (_ctx.draggingEnd($event, { passive: false })))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_TransitionGroup, { type: "transition" }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localNotificationItems, (item, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: item.Id,
                                class: "repeat-sub-notification-item",
                                style: _normalizeStyle({
                    'background-color': _ctx.SetBackgroundColor(_ctx.hoverItemIndex === index),
                  }),
                                onMouseover: ($event: any) => (_ctx.ShowDragAndDropIcon(index)),
                                onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ShowDragAndDropIcon(null)))
                              }, [
                                _createElementVNode("div", _hoisted_17, [
                                  _createElementVNode("span", {
                                    class: _normalizeClass(["color-grey", {'visibility-hidden': (_ctx.hoverItemIndex !== index && !_ctx.isMobileView)}])
                                  }, [
                                    _createElementVNode("img", { src: _ctx.dragDropIcon }, null, 8, _hoisted_18)
                                  ], 2)
                                ]),
                                _createElementVNode("div", _hoisted_19, [
                                  _createElementVNode("img", {
                                    src: _ctx.GetImageUrl(item.NotificationTypeId)
                                  }, null, 8, _hoisted_20)
                                ]),
                                _createElementVNode("div", _hoisted_21, [
                                  _createElementVNode("div", _hoisted_22, [
                                    _createElementVNode("span", _hoisted_23, _toDisplayString(item.Title), 1),
                                    _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                                    _createElementVNode("span", _hoisted_24, [
                                      _createElementVNode("span", null, _toDisplayString(_ctx.getDialogueTypeText(item.NotificationTypeId)), 1)
                                    ])
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_25, [
                                  _createElementVNode("div", _hoisted_26, [
                                    _createElementVNode("div", _hoisted_27, [
                                      _createElementVNode("img", {
                                        src: _ctx.manageIcon,
                                        class: "manage-icon"
                                      }, null, 8, _hoisted_28)
                                    ]),
                                    _createElementVNode("ul", _hoisted_29, [
                                      _createElementVNode("li", {
                                        class: "manage-list-item",
                                        onClick: ($event: any) => (_ctx.CopyNotificationItemFromListing(item))
                                      }, [
                                        _createElementVNode("a", null, _toDisplayString(_ctx.$t("Dialogue.CopySurveyItem")), 1)
                                      ], 8, _hoisted_30),
                                      _createElementVNode("li", {
                                        class: "manage-list-item",
                                        onClick: ($event: any) => (_ctx.DeleteNotificationItemFromListing(item))
                                      }, [
                                        _createElementVNode("a", null, _toDisplayString(_ctx.$t("Delete")), 1)
                                      ], 8, _hoisted_31),
                                      _createElementVNode("li", {
                                        class: "manage-list-item",
                                        onClick: ($event: any) => (_ctx.EditNotificationItemFromListing(item, false))
                                      }, [
                                        _createElementVNode("a", null, _toDisplayString(_ctx.$t("Dialogue.EditSurveyItem")), 1)
                                      ], 8, _hoisted_32)
                                    ])
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_33, [
                                  _createElementVNode("div", _hoisted_34, [
                                    _createElementVNode("ul", _hoisted_35, [
                                      _createElementVNode("li", {
                                        class: "manage-list-item",
                                        onClick: ($event: any) => (_ctx.CopyNotificationItemFromListing(item))
                                      }, [
                                        _createElementVNode("a", null, _toDisplayString(_ctx.$t("Dialogue.CopySurveyItem")), 1)
                                      ], 8, _hoisted_36),
                                      _createElementVNode("li", {
                                        class: "manage-list-item",
                                        onClick: ($event: any) => (_ctx.DeleteNotificationItemFromListing(item))
                                      }, [
                                        _createElementVNode("a", null, _toDisplayString(_ctx.$t("Delete")), 1)
                                      ], 8, _hoisted_37),
                                      _createElementVNode("li", {
                                        class: "manage-list-item",
                                        onClick: ($event: any) => (_ctx.EditNotificationItemFromListing(item, false))
                                      }, [
                                        _createElementVNode("a", null, _toDisplayString(_ctx.$t("Dialogue.EditSurveyItem")), 1)
                                      ], 8, _hoisted_38)
                                    ])
                                  ])
                                ])
                              ], 44, _hoisted_16))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["list", "move"])
                  ])
                ]))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}