import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "right-container-margin" }
const _hoisted_2 = { class: "flex-container" }
const _hoisted_3 = { class: "right-container-nav" }
const _hoisted_4 = { class: "main-title-width" }
const _hoisted_5 = { class: "main-heading primary-heading" }
const _hoisted_6 = { class: "batch-operation" }
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = { class: "description-text" }
const _hoisted_9 = {
  key: 0,
  class: "nav-pills-centering"
}
const _hoisted_10 = {
  id: "pills-tab",
  class: "nav nav-pills mb-3",
  role: "tablist"
}
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = {
  id: "pills-home-tab",
  class: "nav-link active",
  "data-toggle": "pill",
  href: "#pills-home",
  role: "tab",
  "aria-controls": "pills-home",
  "aria-selected": "true"
}
const _hoisted_13 = { class: "nav-item" }
const _hoisted_14 = {
  id: "pills-profile-tab",
  class: "nav-link",
  "data-toggle": "pill",
  href: "#pills-profile",
  role: "tab",
  "aria-controls": "pills-profile",
  "aria-selected": "false"
}
const _hoisted_15 = {
  id: "pills-tabContent",
  class: "tab-content"
}
const _hoisted_16 = {
  class: "batch-operation-main-content",
  style: {"display":"flex"}
}
const _hoisted_17 = ["index"]
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "img-select iconSize" }
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "selectInformationStyle valign-parent60 item-position-from-image display-flex align-items-start" }
const _hoisted_22 = { class: "valign" }
const _hoisted_23 = { class: "hyperLink questionLinkFont no-margin" }
const _hoisted_24 = {
  key: 1,
  class: "action-middle-width"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            class: "btn btn-secondary btn-size-default",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Close()))
          }, _toDisplayString(_ctx.$t("Button.Close")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("Dialogue.CreateNewTitle")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("Dialogue.CreateNewItemDescription")), 1)
        ]),
        false
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("ul", _hoisted_10, [
                _createElementVNode("li", _hoisted_11, [
                  _createElementVNode("a", _hoisted_12, _toDisplayString(_ctx.$t("Dialogue.QuestionTypeDialogue")), 1)
                ]),
                _createElementVNode("li", _hoisted_13, [
                  _createElementVNode("a", _hoisted_14, _toDisplayString(_ctx.$t("Dialogue.IdeasLibrary")), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationTypes.arrNotificationOptions, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.Id,
                  index: index,
                  class: _normalizeClass(["inner-content", 
                  _ctx.evenIndex(index) == true
                    ? 'action-content-width-left'
                    : 'action-content-width-right'
                ])
                }, [
                  (_ctx.ShowNotificationOption(item))
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "inner-content",
                        onClick: ($event: any) => (_ctx.CreateNewNotificationItemTypes(item.Id))
                      }, [
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("img", {
                            class: "icon-img",
                            src: item.IconUrl
                          }, null, 8, _hoisted_20)
                        ]),
                        _createElementVNode("div", _hoisted_21, [
                          _createElementVNode("div", _hoisted_22, [
                            _createElementVNode("label", _hoisted_23, _toDisplayString(item.Name), 1),
                            _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                            _createElementVNode("span", null, _toDisplayString(item.Description), 1)
                          ])
                        ])
                      ], 8, _hoisted_18))
                    : _createCommentVNode("", true),
                  (_ctx.evenIndex(index) && !_ctx.isTabView)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_24))
                    : _createCommentVNode("", true)
                ], 10, _hoisted_17))
              }), 128))
            ])
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("div", {
            id: "pills-profile",
            class: "tab-pane fade",
            role: "tabpanel",
            "aria-labelledby": "pills-profile-tab"
          }, null, -1))
        ])
      ])
    ])
  ]))
}