import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { for: "Folder" }
const _hoisted_2 = ["value", "selected"]
const _hoisted_3 = { class: "margin-top-20" }
const _hoisted_4 = { for: "Folder" }
const _hoisted_5 = { class: "margin-top-20" }
const _hoisted_6 = { for: "Folder" }
const _hoisted_7 = { class: "inline-flex description no-margin" }
const _hoisted_8 = ["checked"]
const _hoisted_9 = { class: "padding-1 margin-left-5" }
const _hoisted_10 = { class: "inline-flex description no-margin" }
const _hoisted_11 = ["checked"]
const _hoisted_12 = { class: "padding-1 margin-left-5" }
const _hoisted_13 = {
  key: 0,
  class: "top-margin-in-fields"
}
const _hoisted_14 = { class: "filter-display" }
const _hoisted_15 = { value: 0 }
const _hoisted_16 = ["value", "selected"]
const _hoisted_17 = { class: "top-margin-in-fields" }
const _hoisted_18 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.$t("Dialogue.Folder")), 1),
    (_ctx.IsCreateNewFolder)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "hyperLink margin-left-20",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showCreateNewOption()))
        }, _toDisplayString(_ctx.$t("Dialogue.CreateNewFolder")), 1))
      : _createCommentVNode("", true),
    (_ctx.IsSelectExisting)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          class: "hyperLink margin-left-20",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showExistingOptions()))
        }, _toDisplayString(_ctx.$t("Dialogue.Selectfolder")), 1))
      : _createCommentVNode("", true),
    (_ctx.IsCreateNewFolder)
      ? (_openBlock(), _createElementBlock("select", {
          key: 2,
          id: "selFolders",
          class: "form-control selectBox",
          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changegroup($event)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableNotificationFolders, (folder, index) => {
            return (_openBlock(), _createElementBlock("option", {
              key: index,
              value: folder,
              selected: folder == _ctx.setSelectedFolder()
            }, _toDisplayString(folder), 9, _hoisted_2))
          }), 128))
        ], 32))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      ref: "CreateNewFolder",
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localFolder) = $event)),
      type: "text",
      class: "form-control",
      maxlength: "100",
      onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changegroup($event)))
    }, null, 544), [
      [_vShow, _ctx.IsSelectExisting],
      [_vModelText, _ctx.localFolder]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("Dialogue.AccessCode")), 1),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localAccessCode) = $event)),
        type: "text",
        class: "form-control",
        maxlength: "100",
        onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.changeaccesscode()))
      }, null, 544), [
        [_vModelText, _ctx.localAccessCode]
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("Dialogue.PublishTo")), 1),
      _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("label", _hoisted_7, [
        _createElementVNode("input", {
          type: "radio",
          name: "publishFilter",
          class: "radio-btn",
          checked: _ctx.localfilerId == 0,
          onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.hideFilters()))
        }, null, 40, _hoisted_8),
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("Dialogue.Everyone")), 1)
      ]),
      _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("label", _hoisted_10, [
        _createElementVNode("input", {
          type: "radio",
          name: "publishFilter",
          class: "radio-btn",
          checked: _ctx.localfilerId > 0,
          onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showFilters()))
        }, null, 40, _hoisted_11),
        _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("Dialogue.SelectAudience")), 1)
      ]),
      (_ctx.IsSelectAudience || _ctx.localfilerId > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("select", {
                class: "form-control filter-dropdown selectBox",
                onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.GetParticipantCount($event, false)))
              }, [
                _createElementVNode("option", _hoisted_15, _toDisplayString(_ctx.$t("Dialogue.SelectFilter")), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localFilters, (filter) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: filter.Id,
                    value: filter.Id,
                    selected: filter.FilterName == _ctx.selectedNotificationFilter
                  }, _toDisplayString(filter.FilterName), 9, _hoisted_16))
                }), 128))
              ], 32),
              (_ctx.IsCreateNewFilter)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: "hyperLink margin-left-20 filterdecoration",
                    onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.ShowCreateNewFilterPane && _ctx.ShowCreateNewFilterPane(...args)))
                  }, _toDisplayString(_ctx.$t("Dialogue.CreateNewFilter")), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t("Dialogue.Filter.CurrentFIlter", {participantCount : _ctx.participantCount})), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}