import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "scroll-top grid-listing right-container-padding" }
const _hoisted_2 = { class: "flex-container" }
const _hoisted_3 = { class: "right-container-nav" }
const _hoisted_4 = { class: "width25" }
const _hoisted_5 = { class: "width50" }
const _hoisted_6 = { class: "main-heading primary-heading" }
const _hoisted_7 = { class: "add-btn-position" }
const _hoisted_8 = { class: "margin-top-25" }
const _hoisted_9 = {
  key: 0,
  class: "padding-top-20 padding-bottom-10"
}
const _hoisted_10 = { class: "display-flex flex-direction-column" }
const _hoisted_11 = { class: "inline-flex description no-margin" }
const _hoisted_12 = ["checked"]
const _hoisted_13 = { class: "padding-1 margin-left-5" }
const _hoisted_14 = { class: "inline-flex description" }
const _hoisted_15 = ["checked"]
const _hoisted_16 = { class: "padding-1 margin-left-5" }
const _hoisted_17 = {
  key: 1,
  class: "datePosition inline-flex100 top-margin-in-fields"
}
const _hoisted_18 = { class: "notification-datepicker" }
const _hoisted_19 = {
  key: 0,
  class: "display-flex align-items-center"
}
const _hoisted_20 = { class: "no-margin margin-left-10 font-style-italic font-weight-normal" }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            class: "btn btn-secondary btn-size-default",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ClosePublishLater()))
          }, _toDisplayString(_ctx.$t("Button.Close")), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("Dialogue.PublishLater")), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            class: "btn btn-primary btn-size-default",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.PublishLater()))
          }, _toDisplayString(_ctx.$t("Dialogue.Save")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_ctx.isValidationSummaryVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_ValidationSummary, {
                "error-fields": _ctx.validationErrorFields,
                onCloseClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.CloseValidationSummary($event)))
              }, null, 8, ["error-fields"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("label", _hoisted_11, [
            _createElementVNode("input", {
              class: "inline-flex top-margin-in-fields publish-later-checkbox",
              type: "radio",
              checked: !_ctx.IsPublishAutomaticSelected,
              name: "publishlaterCheckboxgroup",
              onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.checkedManually($event)))
            }, null, 40, _hoisted_12),
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("Dialogue.PublishLaterManually")), 1)
          ]),
          _createElementVNode("label", _hoisted_14, [
            _createElementVNode("input", {
              class: "inline-flex top-margin-in-fields publish-later-checkbox",
              type: "radio",
              checked: _ctx.IsPublishAutomaticSelected,
              name: "publishlaterCheckboxgroup",
              onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.checkedAutomatic($event)))
            }, null, 40, _hoisted_15),
            _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("Dialogue.PublishAutomatic")), 1)
          ])
        ]),
        (_ctx.IsPublishAutomaticSelected)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _cache[5] || (_cache[5] = _createElementVNode("i", {
                class: "timeZonetooltip",
                "aria-hidden": "true"
              }, null, -1)),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_Datepicker, {
                  id: "scheduledatetimepicker",
                  date: _ctx.GetProgramStartDate(),
                  "full-day-time-format": _ctx.CheckTimeFormat(),
                  "id-value": _ctx.$t('Dialogue.PublishAutomatic'),
                  mindatetime: _ctx.minDateTime,
                  onSelectedDate: _ctx.SetScheduleDateValue
                }, null, 8, ["date", "full-day-time-format", "id-value", "mindatetime", "onSelectedDate"])
              ]),
              (_ctx.timeZone !== '' && _ctx.timeZone !== null)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t("Dialogue.TimezoneText", {timezone: _ctx.timeZone})), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    _createElementVNode("label", {
                      class: "margin-left-10",
                      innerHTML: _ctx.noTimeZoneText
                    }, null, 8, _hoisted_22)
                  ]))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}